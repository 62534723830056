import React from "react"
import { Link as I18nLink, I18nextContext, withTranslation } from "gatsby-plugin-react-i18next";
import { Location } from "@reach/router"
import ContentContext from "../context/ContentContext";
import { Link } from "gatsby"



class LangSelector extends React.Component {

	static contextType = I18nextContext;

	render() {

		const checkUrlAndAddSlash = (url) => {
			return url.slice(-1) !== '/' ? url + '/' : url;
		}

		return <ul className="navbar-nav languages">
			<ContentContext.Consumer>{content => {
				if (content.translations) {
					return this.context.languages.map((lng) => {
						const translation = content.translations.find(translation => (translation.lang === lng));
						if (translation) {
							const pathWithSlash = checkUrlAndAddSlash(translation.path);
							return <li className="nav-item" key={lng}>
								<Link to={pathWithSlash} className="nav-link nav-link-language">
									{lng}
								</Link>
							</li>
						} else {
							return <React.Fragment key={lng} />
						}
					});
				} else {
					return <Location>
						{({ location }) => {
							const language = location.pathname.replace("/" + this.context.language, "");
							return this.context.languages.map((lng) => (
								<li className="nav-item" key={lng}>
									<I18nLink to={language + location.search || ''} language={lng} className="nav-link nav-link-language">
										{lng}
									</I18nLink>
								</li>
							))
						}}
					</Location>
				}
			}}
			</ContentContext.Consumer>
		</ul>
	}
}

export default withTranslation('LangSelector')(LangSelector);
