import React from 'react'
import NavigationBar from '../components/NavigationBar'
import PageFooter from '../components/PageFooter'
import CookieValidation from "./CookieValidation";


export default function Layout({ children }) {
  return (
		<>
			<div className="container principal">
				<NavigationBar/>
				<main>
					{children}
				</main>
			</div>
			<PageFooter/>
			<CookieValidation/>
		</>
	)
}
