import React from "react"
import { Link as I18nLink, withTranslation } from 'gatsby-plugin-react-i18next';
import LangSelector from '../components/LangSelector';
import { StaticQuery, graphql, Link } from "gatsby"


class NavigationBar extends React.Component {
	
  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  componentDidMount() {
	window.$('body').css('overflow', 'auto');

	window.$('#navbarPrincipal').on('show.bs.collapse', function () {
		window.$('#navbarPrincipal').addClass('navbar-custom-toggle');
		window.$('body').css('overflow', 'hidden');
		window.$('.icon-logo-camino-masca').css('display', 'none');
	});

	window.$('#navbarPrincipal').on('shown.bs.collapse', function () {
		window.$("#navbarDropdown").dropdown('show');
	});

	window.$('#navbarPrincipal').on('hidden.bs.collapse', function () {
		window.$('#navbarPrincipal').removeClass('navbar-custom-toggle');
		window.$('body').css('overflow', 'auto');
		if(window.showBrand) {
			window.$('.icon-logo-camino-masca').css('display', 'block');
		}
	});
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  render() {
    const { t } = this.props;
	const language = this.props.i18n.language;

    return <React.Fragment>
		<nav className="navbar navbar-expand-lg navbar-dark bg-dark navigation-bar sticky-top">
			<a className="navbar-brand" href="/" aria-label="Camino del barranco de Masca">
				<div className="icon-logo-camino-masca"></div>
			</a>
			<button className="navbar-toggler" type="button"
				data-toggle="collapse" data-target="#navbarPrincipal"
				aria-controls="navbarPrincipal" aria-expanded="false" aria-label="Toggle navigation">
			<span className="navbar-toggler-icon" />
			</button>
			<div className="collapse navbar-collapse" id="navbarPrincipal">
				<ul className="navbar-nav ml-auto mr-4">
					<StaticQuery query={graphql`
						query SiteTitleQuery {
							site {
								siteMetadata {
									menuLinks {
										name
										path
										icon
										section
										article
										experience
									}
								}
							}
							allLocalizedArticle(sort: {fields: [section, lang, order], order: ASC}) {
								edges {
									node {
										articleId
										name
										path
										lang
										section
									}
								}
							}
							allExperience {
								edges {
									node {
										experienceId
										path
										lang
									}
								}
							}
						}
					`}
					render={data => (
						data.site.siteMetadata.menuLinks.map(link => {
							let linkPath;
							if (link.path) {
								return <li className="nav-item" key={link.name}>
									<I18nLink className="clic_menu_header nav-link" to={link.path} language={language}>
										<span className={link.icon}></span>
										<span>{t(link.name)}</span>
									</I18nLink>
								</li>
							} else if (link.article) {
								const article = data.allLocalizedArticle.edges.find(article => ((article.node.articleId === link.article) && (article.node.lang === language)));
								if (article) {
									linkPath = article.node.path;
								} else {
									return <React.Fragment key={link.name}/>
								}
							} else if (link.experience) {
								const experience = data.allExperience.edges.find(experience => ((experience.node.experienceId === link.experience) && (experience.node.lang === language)));
								if (experience) {
									linkPath = experience.node.path;
								} else {
									return <React.Fragment key={link.name}/>
								}
							} else if (link.section) {
								const articles = data.allLocalizedArticle.edges.filter(article => ((article.node.section === link.section) && (article.node.lang === language)));
								return 	<li className={"nav-item dropdown"} key={link.name}>
											<Link to="/#" className={"clic_menu_header nav-link dropdown-toggle"} id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<span className={link.icon}></span>
												<span>{t(link.name)}</span>
											</Link>
											<div className={"dropdown-menu"} aria-labelledby="navbarDropdown">
												<span className={"dropdown-menu-arrow d-none d-lg-block"}></span>
												{articles.map((item, i) => {
													return (<React.Fragment key={i}>
																<div className={`dropdown-divider ${i === 0 ? 'd-none' : ''}`}></div>
														<Link className={"clic_menu_header dropdown-item"} to={item.node.path + '/'}>
																	<span>{t(item.node.name)}</span>
																</Link>
															</React.Fragment>
													);
												})}
											</div>
										</li>;
							} else {
								return <React.Fragment key={link.name}/>
							}
							
							return <li className="nav-item" key={link.name}>
									<Link className="clic_menu_header nav-link" to={linkPath + '/'}>
										<span className={link.icon}></span>
										<span>{t(link.name)}</span>
									</Link>
								</li>
							}
						))}
					/>
				</ul>
				<LangSelector></LangSelector>
			</div>
		</nav>
	</React.Fragment>;
  }
}

export default withTranslation('NavigationBar')(NavigationBar);
